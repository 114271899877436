import HomePage from './pages/home';

function App() {

  return (
    <>
     <HomePage />
    </>
  )
}

export default App
