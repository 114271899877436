import React, { useEffect, useState } from 'react';
import { RiMenu2Line, RiCloseLine } from "react-icons/ri";
import { Link } from 'react-scroll';

const Header = () => {
  const [smallNav, setSmallNav] = useState(false);
  const [openNavLinks, setOpenNavLinks] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setSmallNav(false);
        setOpenNavLinks(false);
      }
      return () => window.removeEventListener('scroll', () => {});
    })

    window.addEventListener('resize', () => {
      if (window.innerWidth > 810) {
        setSmallNav(false);
        setOpenNavLinks(false);
      }
      return () => window.removeEventListener('resize', () => {});
    })
  }, []);

  const toggleNavLinks = () => {
    if (openNavLinks === false) {
      setSmallNav(true);
      const timeout = setTimeout(() => {
        setOpenNavLinks(true);
      }, [500]);
      
      return () => clearTimeout(timeout);
    } else {
      setSmallNav(false);
      setOpenNavLinks(false);
    }
  }

  const links = [
    {name: 'Buy', link: 'buy'},
    {name: 'FAQ', link: 'faq'},
    {name: 'About', link: 'about'},
    {name: 'Tokenomics', link: 'tokenomics'},
    {name: 'Socials', link: 'socials'}
  ]
  return (
    <header 
      className={`fixed top-0 left-0 z-[50] flex items-center justify-between w-full ${smallNav? 'h-[4.6875rem]' : 'h-[3.4375rem]'} md:h-[7.1875rem] backdrop-blur-[5px] bg-black/20 border-solid border-x-0 border-t-0 border-b-[1px] border-secondary/50 ease-250 `}
    >
      <div
        className={`relative flex items-center justify-between h-full w-full px-[1rem] sm:px-[2rem] ease-250 `}
      >
        <Link 
          to='home' 
          className='flex items-center gap-[1rem] h-full w-max'
          spy={true}
          smooth={true}
          duration={500}
          offset={-100}
        >
          <img 
            src='/assets/images/section2_bg.png'
            alt='LOGO'
            className='w-[2.6875rem] h-[2.6875rem] min-w-[2.6875rem] md:w-[4.5rem] md:h-[4.5rem] md:min-w-[4.5rem] rounded-[100rem] object-cover '
          />
          <h1 className='text-[1.25rem] md:text-[1.75rem] font-[600] text-white '>JAIL FAUCI</h1>

        </Link>

        {/* ::::::::::::::::::::: NAV */}
        <nav className='hidden md:flex items-center gap-[0.875rem] lg:gap-[1rem] h-full'>
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.link} // Assuming link.link is the id of the target section
            spy={true}
            smooth={true}
            duration={500}
            offset={-100}
            className='group relative text-[1rem] lg:text-[1.25rem] font-[600] text-white hover:text-white/80 ease-250'
          >
            {link.name}
            <span className='absolute bottom-[-0.5rem] left-0 w-0 group-hover:w-full h-[1px] bg-secondary ease-250'></span>
          </Link>
        ))}
          <a 
            href='https://t.me/jailfaucisol'
            target='_blank'
            rel='noreferrer'
            className='flex justify-center items-center text-[1rem] lg:text-[1.125rem] font-[600] w-[9rem] lg:w-[10.5625rem] h-[2.5rem] lg:h-[3rem] text-black bg-primary hover:bg-transparent hover:text-white hover:border-solid hover:border-[1px] hover:border-white ease-250 rounded-[12px] '
          >Join Telegram</a>
        </nav>
        
        {/* :::::::::::::::::::::::::: SMALL NAV BUTTON */}
        <button
          onClick={() => {
            toggleNavLinks();
          }}
          className='flex items-center md:hidden relative h-full'
        >
          {!smallNav? 
          <RiMenu2Line className={`text-secondary text-[1.5rem] `} /> :
          <RiCloseLine className={`text-secondary text-[1.5rem]`} />}
        </button>
        
        {/* :::::::::::::::::::::::: SMALL NAV */}
        <div 
          className={`absolute top-[calc(100%+1px)] right-0 z-[100] w-full bg-black/20 backdrop-blur-[5px]`}
        >
          {openNavLinks && 
          <div className={`flex flex-col items-center w-full p-[2rem] pb-0 ease-250`}>
            {links.map((link, index) => (
              <Link
                key={index}
                to={link.link} // Assuming link.link is the id of the target section
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                onClick={toggleNavLinks}
                className='group relative text-center text-[1.125rem] md:text-[1.25rem] font-[600] text-white hover:text-white/80 w-full border-solid border-x-0 border-t-0 border-b-[1px] border-secondary/50 pb-[0.5rem] ease-250'
              >
                {link.name}
              </Link>
            ))}
          </div>
          }
        </div>
      </div>
    </header>
  )
}

export default Header;