import SectionHeader from 'components/typography/section_header';
import React from 'react';
import { motion } from 'framer-motion';

const S2_Section = () => {
  const items = [
    {
      image: '/assets/images/about01.jpg',
      title: 'About Jail Fauci:',
      text: `From 1 mask to 3 masks, we're here to expose the bullshit. From the lab leak theory, the vaccine & muh boosters to suppressions of OTC medications and trying to keep people indoors away from the sunlight… basically we're tired of seeing all these crooks walking in the public domain instead of serving their justice. Our meme & justice token is for all those that were affected from this scam, want to spread knowledge to the masses and are not ok with what occurred. Join us on this wild ride of giveaways, donations to project causes, and dropping facts/knowledge that may be helpful for loved ones to help bridge a societal gap.`     
    },
    {
      image: '/assets/images/about02.jpg',
      title: 'The Mission',
      text: `There is not a demand within the sort of narratives Jail Fauci is representing in the crypto space yet. We are one-of-a-kind. Jail Fauci is looking to start a trend that will encourage others in the community to stand up and fight back against the evil forces that fight tooth and nail to keep the collective of humanity from leveling up. We strive to make voices that are battling in the trenches louder, in order to positively influence the ascension of humanity. Don’t worry, when Tony Bologna finally gets arrested, the bus doesn’t stop there. We’ll be working our way up the ladder to his puppet masters.`
    }
  ]

  const imageVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <div id='about' className="relative flex flex-col items-center justify-center gap-[3rem] w-full h-max bg-black py-[2rem] pb-[8rem] border-solid border-t-[1px] border-b-[1px] border-x-0 border-primary/50 ">
      <SectionHeader text='About Jail Fauci' />

      <div className='flex flex-col gap-[4rem] '>
        {items.map((item, index) => (
          <motion.div
            key={index}
            className='flex max-md:flex-col items-center gap-[1.5rem] h-max w-full max-w-[73.9375rem] px-[2rem]'
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: false }}
          >
            <motion.img
              src={item.image}
              alt='background'
              className='w-[11.25rem] h-[11.25rem] min-w-[11.25rem] object-cover rounded-[100rem] outline outline-[2px] outline-primary'
              variants={imageVariants}
            />

            <motion.div
              className='relative flex flex-col gap-[0.5rem] pl-[1.5rem] overflow-y-hidden'
              variants={textVariants}
            >
              <div className='absolute top-[0] left-0 bg-primary/50 w-[1px] h-full'></div>
              <h2 className='max-md:mx-auto text-primary text-[1.4375rem] font-[600]'>
                {item.title}
              </h2>
              <p>{item.text}</p>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </div>
  )
}

export default S2_Section;
