import React from 'react'
import Header from '../header'
import Footer from '../footer'

const MainLayout = ({ children }) => {
  return (
    <div className='relative w-full min-h-screen bg-black'>
      <Header />
      {children}
      <Footer />  
    </div>
  )
}

export default MainLayout