/* eslint-disable react/jsx-pascal-case */
import MainLayout from 'components/layout';
import HeroSection from './components/s1_hero';
import S2_Section from './components/s2_section';
import S3_Section from './components/s3_section';
import S4_Section from './components/s4_section';
import S5_Section from './components/s5_section';
import S6_Section from './components/s6_section';
// import S7_Section from './components/s7_section';

const HomePage = () => {
  return (
    <MainLayout>
      <HeroSection />
      <S2_Section />
      <S3_Section />
      <S4_Section />
      <S5_Section />
      <S6_Section />
      {/* <S7_Section /> */}
    </MainLayout>
  )
}

export default HomePage