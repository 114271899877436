import SectionHeader from 'components/typography/section_header';
import { useEffect, useRef } from 'react';
import { RiShoppingCart2Line, RiLinksLine } from 'react-icons/ri';
import { motion } from 'framer-motion';

const S3_Section = () => {
  // const [copied, setCopied] = useState(false);

  // const handleCopy = () => {
  //   const textToCopy = 'HqS1CpWwRcJydwn6pGBDhHAwJu3Mxpk6qJgvys9fU4X5';
  //   navigator.clipboard.writeText(textToCopy)
  //     .then(() => setCopied(true))
  //     .catch((error) => console.error('Failed to copy:', error));
  // };

  // useEffect(() => {
  //   if (copied) {
  //     const timeoutId = setTimeout(() => {
  //       setCopied(false);
  //     }, 4000);

  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [copied]);

  const items = [
    {text: 'To Buy $Fraudci', text1: 'Download Phantom Wallet'},
    {text: 'Purchase $SOL From An Exchange Or Bridge $SOL And Send It To Your Phantom Wallet'},
    {text: 'Then Buy $Fraudci On Jupiter Or Raydium.'},
  ]

  const backgroundRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (backgroundRef.current) {
        const scrollPosition = window.scrollY;
        const scale = 1 + scrollPosition * 0.0005;
        backgroundRef.current.style.transform = `scale(${scale})`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  // :::::::::::::::::::::::::: FRAMER MOTION CODE  
  const divVariant = {
    hidden: { y: 40, opacity: 0},
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.5, ease: 'easeInOut' }
    }
  }

  const textVariant = {
    hidden: { opacity: 0},
    visible: {
      opacity: 1,
      transition: { duration: 0.5, ease: 'easeInOut' }
    }
  }

  return (
    <div id='buy' className="relative flex flex-col items-center justify-center gap-[5rem] bg-gradient-to-b from-black via-black/80 to-black py-[2rem] pb-[5rem] overflow-hidden  ">
      <SectionHeader text='Swoop Up Some $Fraudci' />
      
      <div className=''>
        {items.map((item, index) => (
          <div
            key={index}
            className='flex flex-col gap-[0.5rem] items-center text-center'
          >
            <motion.p 
              variants={textVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.5, once: false }}
              className='flex items-center text-center justify-center bg-gradient-to-b from-primary-dark to-primary w-max h-[3.5rem] rounded-[8px] mx-auto px-[2rem] text-[1.875rem] font-[600] text-black'
            >
              0{index+1}
            </motion.p>
            <p className={`text-[31px] font-[600] mx-auto ${index===1? 'max-w-[38.375rem]' : 'max-w-[20rem]'}`}>{item.text}</p>
            {item.text1 && <p className='text-[31px] font-[600] mx-auto max-w-[38.375rem]'>{item.text1}</p>}

            {/* :::::::::::: LINE */}
            {index !== 2 && 
            <motion.div 
              className='h-[9.25rem] w-[2px] bg-primary mx-auto my-[2rem] '
              variants={divVariant}
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.5, once: false }}
            ></motion.div>
            }
          </div>
        ))}
      </div>

      <motion.div 
        className='flex gap-[1rem] items-center max-md:flex-col pt-[1rem] '
        variants={divVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.5, once: false }}
      >
        <a 
          href='https://phantom.app/'
          target='_blank'
          rel='noreferrer'
          className='flex items-center justify-center gap-[1rem] min-w-[12.875rem] h-[2.5rem] md:h-[3.375rem] bg-transparent rounded-[8px] px-[1rem] md:px-[1.25rem] border-solid border-[1px] border-white hover:opacity-[90%] transform ease-250 hover:scale-[1.05]'
        >
          <p className='text-white text-[0.9375rem] md:text-[1.0625rem] font-[600] '>Phantom</p>
          <RiLinksLine className='text-[#ffffff] text-[1.5rem] ' />
        </a>
        <a 
          href='https://jup.ag/swap/SOL-HqS1CpWwRcJydwn6pGBDhHAwJu3Mxpk6qJgvys9fU4X5'
          target='_blank'
          rel='noreferrer'
          className='flex items-center justify-center gap-[1rem] min-w-[12.875rem] h-[2.5rem] md:h-[3.375rem] bg-white rounded-[8px] px-[1rem] md:px-[1.25rem] hover:opacity-[95%] transform ease-250 hover:scale-[1.05]'
        >
          <p className='text-black text-[0.9375rem] md:text-[1.0625rem] font-[600] '>Buy on Jupiter</p>
          <RiShoppingCart2Line className='text-[#000000] text-[1.5rem] ' />
        </a>
      </motion.div>

      {/* :::::::::::::::::::::: BACKGROUND IMAGE */}
      <img
        src='/assets/images/section2_bg.png'
        alt='background'
        className='absolute z-[-1] top-0 left-0 w-full h-full object-cover transition-all duration-[100ms] ease-in-out'
        ref={backgroundRef}
      />
    </div>
  )
}

export default S3_Section