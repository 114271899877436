import { RiShoppingCart2Line } from 'react-icons/ri';
import { motion } from 'framer-motion';

const HeroSection = () => {
//   const socials = [
//     {
//       name: 'Twitter',
//       url: 'https://x.com/JailFauci',
//       icon: 'x.svg',
//     },
//     {
//       name: 'Telegram',
//       url: 'https://t.me/jailfaucisol',
//       icon: 'telegram.svg',
//     },
//     {
//       name: 'Dexscreener',
//       url: 'https://dexscreener.com/solana/5SGv2E8YJYggSHshcqnuPQFhf72ygdgw1upW27WXxRXH',
//       icon: 'dexscreener.svg',
//     }
//   ]
  
  const divVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.25,
        ease: 'easeInOut',
      }
    }
  }

  return (
    <div id='home' className="relative z-[1] flex w-full h-max min-h-[50rem] bg-primaryHover overflow-x-hidden pb-[5rem] pt-[4rem] md:pt-[14rem] ">
      <div className='flex max-md:flex-col-reverse justify-between gap-y-[1rem] items-center py-[2rem] px-[2rem] w-full max-w-[66.0625rem] mx-auto '>  
        <div className='relative flex flex-col md:gap-[0.5rem] w-full max-md:text-center max-md:items-center md:max-w-[28.75rem] '>
          {/* <h2 className='text-[3.625rem] font-[600] '>Hop on</h2> */}
          <h2 className='text-[3.5rem] md:text-[5.25rem] font-[800] text-primary'>Jail Fauci</h2>
          <p className='text-[0.8125rem] '>Welcome to Jail Fauci $Fraudci, a rally call to arrest the crook of the century. We’re not puppy dogs, apes and all that other stuff. We’re here to spread awareness, facts and knowledge that what occurred the last few years was not ok, so we are not damned to repeat it. We’re starting at Mr. Tony Bologna first, then climbing up the ladder as justice is served.</p>

          {/* ::::::::::::::::::::::::::: CTA */}
          <motion.div 
            className='flex gap-[1rem] items-center max-md:flex-col pt-[1rem] '
            variants={divVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: false }}
          >
            <a 
              href='https://jup.ag/swap/SOL-HqS1CpWwRcJydwn6pGBDhHAwJu3Mxpk6qJgvys9fU4X5'
              target='_blank'
              rel='noreferrer'
              className='flex items-center gap-[1rem] h-[2.5rem] md:h-[3.375rem] bg-white rounded-[8px] px-[1rem] md:px-[1.25rem] hover:opacity-[95%] transform ease-250 hover:scale-[1.05]'
            >
              <p className='text-black text-[0.9375rem] md:text-[1.0625rem] font-[600] '>Buy on Jupiter</p>
              <RiShoppingCart2Line className='text-[#000000] text-[1.5rem] ' />
            </a>
            <a 
              href='https://dexscreener.com/solana/5SGv2E8YJYggSHshcqnuPQFhf72ygdgw1upW27WXxRXH'
              target='_blank'
              rel='noreferrer'
              className='flex items-center justify-center gap-[1rem] h-[2.5rem] md:h-[3.375rem] min-w-[11rem] bg-transparent rounded-[8px] px-[1rem] md:px-[1.25rem] border-solid border-[1px] border-white hover:opacity-[90%] transform ease-250 hover:scale-[1.05]'
            >
              <p className='text-white text-[0.9375rem] md:text-[1.0625rem] font-[600] '>See the Chart</p>
            </a>
          </motion.div>

          {/* :::::::::::::::::: JAIL EXHIBIT */}
          {/* <img
            src='/assets/images/section2_bg.png'
            alt='JAIL EXHIBIT'
            className='max-md:hidden absolute top-0 right-[2rem] z-[5] w-[5rem] object-contain shake'
          /> */}
        </div>
        
        <div className='flex items-center justify-center md:justify-end w-[18.75rem] h-[18.75rem] min-w-[18.75rem] '>
          <img
            src='/assets/images/section2_bg.png'
            alt='LOGO'
            className='w-[14.75rem] h-[14.75rem] min-w-[14.75rem] object-cover rounded-[100rem] hover:scale-[1.1] shadow-sm shadow-[#d68e07] ease-250'
          />
        </div>
      </div>

      {/* ::::::::::::::::::::::: OVERLAY */}
      <div className='absolute z-[-1] top-0 left-0 w-full h-full bg-gradient-to-b from-[#000000] via-[#111111] to-[#000000] opacity-[90%] '>
        <img
          src='/assets/images/hero_bg.png'
          alt='background'
          className='w-full h-full object-cover opacity-[20%] '
        />
      </div>
    </div>
  )
}

export default HeroSection;