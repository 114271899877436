import { RiTwitterLine } from "react-icons/ri";
import { PiTelegramLogoDuotone } from "react-icons/pi";
import { motion } from 'framer-motion';

const Footer = () => {
  const socials = [
    {
      name: 'Twitter',
      url: 'https://x.com/JailFauci',
      icon: <RiTwitterLine className='text-[1.875rem] md:text-[2.5rem] text-white' />
    },
    {
      name: 'Telegram',
      url: 'https://t.me/jailfaucisol',
      icon: <PiTelegramLogoDuotone className='text-[1.875rem] md:text-[2.5rem] text-white' />
    },
    {
      name: 'Dexscreener',
      url: 'https://dexscreener.com/solana/5SGv2E8YJYggSHshcqnuPQFhf72ygdgw1upW27WXxRXH',
      icon: 'dexscreener_white.svg'
    }
  ]

  const iconLinkVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
      y: 20,
    },
    visible: (index) => ({
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 0.25,
        ease: 'easeInOut',
        delay: index * 0.08
      }
    })
  }

  return (
    <div className="relative flex items-center justify-center gap-[1rem] md:gap-[3rem] w-full h-max bg-black  rounded-t-[5rem] pl-[1rem] md:pl-[2rem] ">
      <div className='absolute top-0 left-0 w-full h-[4rem] border-solid border-t-[1px] border-x-[1px] border-b-0 border-primary/50 rounded-t-[5rem] '></div>
      <div className='flex flex-col gap-[0.5rem] md:gap-[1rem] items-center pb-[2rem] '>
      {socials.map((social, index) => (
        <motion.a 
          key={index}
          href={social.link}
          className='mt-[2rem]'
          custom={index}
          variants={iconLinkVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.5, once: false }}
          whileHover={{ scale: 1.1, transition: { duration: 0.25, ease: 'easeInOut' } }}
        >
          {index === 2?
          <img src={`/assets/svgs/${social.icon}`} alt='DEXSCREENER' className='h-[1.875rem] md:h-[2.5rem] w-[1.875rem] md:w-[2.5rem] min-w-[1.875rem] md:min-w-[2.5rem]' /> :
          <>{social.icon}</>
          }
        </motion.a>
      ))}
      </div>

      <div className='flex flex-1 flex-col justify-center gap-[1rem]'>
        <h2 className='text-[2rem] md:text-[3.5rem] font-[800] text-primary'>JAIL FAUCI</h2>
        <p className='max-w-full text-[0.875rem] md:text-[1.5rem] text-secondary pr-[1rem] '>HEADED FOR 1 BILLION MARKETCAP THEN TO INFINITY AND BEYOND.</p>

        <hr className='w-full h-[1px] bg-primary/50 my-[1rem] ' />

        <p className='text-[0.75rem] md:pl-[1rem] mt-[-1rem]'>© {new Date().getFullYear()} Jail Fauci • All Rights Reserved</p>
      </div>
    </div>
  )
}

export default Footer