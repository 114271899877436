import SectionHeader from 'components/typography/section_header';
import { RiTwitterLine } from "react-icons/ri";
import { PiTelegramLogoDuotone } from "react-icons/pi";
import { motion } from 'framer-motion';

const S5_Section = () => {
  const socials = [
    {
      name: 'Twitter',
      url: 'https://x.com/JailFauci',
      icon: <RiTwitterLine className='text-[4rem] text-primary' />
    },
    {
      name: 'Telegram',
      url: 'https://t.me/jailfaucisol',
      icon: <PiTelegramLogoDuotone className='text-[4rem] text-primary' />
    },
    {
      name: 'Dexscreener',
      url: 'https://dexscreener.com/solana/5SGv2E8YJYggSHshcqnuPQFhf72ygdgw1upW27WXxRXH',
      icon: 'dexscreener.svg'
    }
  ]

  const iconLinkVariants = {
    hidden: {
      opacity: 0,
      scale: 0
    },
    visible: (index) => ({
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.25,
        ease: 'easeInOut',
        delay: index * 0.08
      }
    })
  }

  return (
    <div id='socials' className='relative z-[1] flex flex-col gap-[3rem] items-center justify-center w-full bg-black pt-[2rem] pb-[6rem] overflow-x-hidden border-b-[1px] border-x-0 border-t-0 border-solid border-primary/50 '>
      <SectionHeader text='Socials' />
      
      <div className='flex max-md:flex-col gap-[4rem] items-center justify-center px-[1rem] md:px-[2rem] w-full '>
        <div className='w-[10rem] h-[10rem] md:w-[13.1875rem] md:h-[13.1875rem] p-[1rem] rounded-[56px] border-solid border-primary/50 border-[1px] shadow-[0_5px_25px_8px_rgba(255,166,0,0.2)] overflow-hidden'>
          <img
            src='/assets/images/section2_bg.png'
            alt='LOGO'
            className='w-full h-full object-cover'
          />
        </div>

        {/* ::::::::::::::::::: SOCIAL CONTENT */}
        <div className='flex flex-col h-full justify-between'>
          <h2 className='text-[2.4375rem] md:text-[2.8125rem] max-w-[22rem] md:max-w-[25rem] text-center font-[800]'>Follow JAILFAUCI On Social Media</h2>
          <div className='flex items-center justify-between w-full px-[1rem]'>
            {socials.map((social, index) => (
              <motion.a 
                key={index}
                href={social.url}
                className='mt-[2rem]'
                custom={index}
                variants={iconLinkVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.5, once: false }}
                whileHover={{ scale: 1.1, transition: { duration: 0.25, ease: 'easeInOut' } }}
              >
                {index === 2 ?
                  <img src={`/assets/svgs/${social.icon}`} alt='DEXSCREENER' className='h-[3.5rem] w-[3.5rem] min-w-[3.5rem]' /> :
                  <>{social.icon}</>
                }
              </motion.a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default S5_Section;
